import contactImage from '../images/contact_icon.png';
import newMandateImage from '../images/mandate_create_icon.png';
import mandateRegisterImage from '../images/mandate_register_icon.png';
import qaImage from '../images/qa_icon.png';
import fmpImage from '../images/fmp_icon.png';

const anchorLinks = [
  {
    title: 'Mandat anlegen',
    contactImage: contactImage,
    imageStyle: { width: 39, height: 33 },
    anchor: '#create-mandate',
    image: newMandateImage
  },
  {
    title: 'Mandat registrieren',
    image: mandateRegisterImage,
    anchor: '#mandate-register',
    imageStyle: { width: 46, height: 26 }
  },
  {
    title: 'FAQ',
    image: qaImage,
    anchor: '#faq',
    imageStyle: { width: 35, height: 35 }
  },
  {
    title: 'Über uns',
    anchor: '#about-us',
    imageStyle: { width: 47, height: 33 },
    image: fmpImage
  },
  {
    title: 'Kontakt',
    image: contactImage,
    anchor: '#contact',
    imageStyle: { width: 30, height: 30 }
  }
];

export default anchorLinks;
