import React, { useState } from 'react';
import Typist from 'react-typist';
import cn from 'classnames';

import calendarIcon from '../../images/calendar_icon.png';
import IconSearch from '../../images/svg/IconSearch.svg';
import IconFinished from '../../images/svg/IconFinished.svg';
import IconHand from '../../images/svg/IconHand.svg';

import styles from './RegistrySearch.module.scss';

const RegistrySearch = () => {
  const [isFinished, setIsFinished] = useState(false);
  const [isButtonClicking, setIsButtonClicking] = useState(false);
  const [isHandVisible, setIsHandVisible] = useState(false);

  const toggleIsFinished = () => {
    setIsFinished(true);
    setIsButtonClicking(false);
    setIsHandVisible(false);
    setTimeout(() => setIsFinished(false), 5000);
  };

  const clickButton = () => {
    setIsButtonClicking(true);
    setTimeout(toggleIsFinished, 800);
  };

  const showHand = () => {
    setIsHandVisible(true);
    setTimeout(clickButton, 800);
  };

  const onTypingDone = () => {
    setTimeout(showHand, 400);
  };

  if (isFinished) {
    return (
      <div className={styles.registrySearch}>
        <div className={styles.registrySearchIcon}>
          <IconFinished />
        </div>
        <h4 className={styles.registrySearchTitle} style={{ color: '#529900' }}>
          MANDAT VORHANDEN
        </h4>
        <p className={styles.registrySearchInfo}>Maria Mustermann, geb. am 01.01.1940</p>
        <div className={styles.buttonsContainer}>
          <button className={cn(styles.button, styles.contactButton)}>
            Kontakt aufnehmen*
          </button>
          <button className={cn(styles.button, styles.returnButton)}>
            Zurück zur suche
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.registrySearch}>
      <div className={styles.registrySearchIcon}>
        <IconSearch />
      </div>
      <h4 className={styles.registrySearchTitle}>MANDATSUCHE</h4>
      <div className={styles.registrySearchForm}>
        <div className={styles.registrySearchInputs}>
          <div className={styles.firstName}>
            <p>Vorname</p>
            <div className={styles.input}>
              {!isFinished && (
                <Typist startDelay={400} avgTypingDelay={100} cursor={{ show: false }}>
                  Maria
                </Typist>
              )}
            </div>
          </div>
          <div className={styles.secondName}>
            <p>Nachname</p>
            <div className={styles.input}>
              {!isFinished && (
                <Typist startDelay={1400} avgTypingDelay={100} cursor={{ show: false }}>
                  Mustermann
                </Typist>
              )}
            </div>
          </div>
          <div className={styles.date}>
            <p>Geburtsdatum</p>
            <div className={styles.input}>
              {!isFinished && (
                <Typist
                  startDelay={3000}
                  avgTypingDelay={100}
                  cursor={{ show: false }}
                  onTypingDone={onTypingDone}
                >
                  01.01.1940
                </Typist>
              )}
              <img src={calendarIcon} alt="date" />
            </div>
          </div>
        </div>
        <button
          className={cn(styles.button, {
            [styles.button_active]: isButtonClicking
          })}
        >
          Mandat finden
          <IconHand
            className={cn(styles.handImage, {
              [styles.handImage_visible]: isHandVisible
            })}
          />
        </button>
      </div>
    </div>
  );
};

export default RegistrySearch;
