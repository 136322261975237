import personalInfoImage from '../images/mandate-features/personal-info.png';
import createDocumentsImage from '../images/mandate-features/create-documents.png';
import checklistImage from '../images/mandate-features/checklist.png';
import documentsImage from '../images/mandate-features/documents.png';
import registrationImage from '../images/mandate-features/registration.png';

const mandateFeatures = [
  {
    type: 'free',
    icon: personalInfoImage,
    title: 'PERSONENDATEN HINTERLEGEN:',
    text: 'Für den Notfall oder Sterbefall, sowie für eine Vertrauensperson'
  },
  {
    type: 'free',
    icon: createDocumentsImage,
    title: 'DOKUMENTE ERSTELLEN:',
    text: 'Mit Hilfe von Formular-vorlagen, wie z.B. Patientenverfügung oder Testament'
  },
  {
    type: 'free',
    icon: checklistImage,
    title: 'DOKUMENTE HINTERLEGEN:',
    text: 'Mit Hilfe von Dokumenten-Checklisten für den Notfall oder Sterbefall'
  },
  {
    type: 'free',
    icon: documentsImage,
    title: 'DOKUMENTE VERWALTEN:',
    text: 'Einfach herunterladen, löschen, versenden oder aktualisieren'
  },
  {
    type: 'paid',
    icon: registrationImage,
    title: 'IM ZENTRALREGISTER REGISTRIEREN UND NOTFALLKARTE ERHALTEN:',
    text: 'Über Name und Geburts-datum in unserer Datenbank gefunden werden'
  }
];

export default mandateFeatures;
