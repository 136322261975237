import getFmpUrl from 'functions/urls/getFmpUrl';

const faqsItems = [
  {
    question: 'Was ist ein Mandat?',
    answer:
      'Ein Mandat ist Ihr persönlicher Bereich auf dem Funeral Marketplace. Hier verwalten Sie Ihre persönlichen Informationen, Verfügungen und Vollmachten und all Ihre anderen Notfalldokumente. Wenn Sie einen Dienstleister auswählen, erhält dieser Zugriff auf diese Informationen, um die Bestattung planen und durchführen zu können.'
  },
  {
    question:
      'Warum ist das Anlegen eines Mandats kostenfrei, das Registrieren jedoch kostenpflichtig?',
    answer:
      'Wir möchten Ihnen die Möglichkeit geben, sich ein Mandat anzulegen, ohne etwas im Voraus zahlen zu müssen. Das umfasst bereits viele Funktionen, wie das Ausfüllen von Formularen und Ihre persönliche Dokumenten-Cloud.<br />' +
      'Es ist von höchster Bedeutung, dass Ihre Angehörigen Ihre Vorsorgeregelungen im Ernstfall schnell und einfach in unserem Zentralregister finden. Daher ist eine konstante technische Wartung und Weiterentwicklung notwendig, die Sie über Ihre kostenpflichtige Registrierung ermöglichen.'
  },
  {
    question:
      'Wie sicher ist das erstellen, Hinterlegen und Verwalten von Dokumenten auf dem Funeral Marketplace?',
    answer:
      'Die von Ihnen hinterlegten Informationen und Dokumente sind jederzeit sicher. Der Schutz Ihrer Daten und höchste technische Sicherheit sind für uns von zentraler Bedeutung. Daher hosten wir Ihre Daten sicher und entsprechend der DSGVO ausschließlich auf Servern in Deutschland und nutzen verschlüsselte SSL-Verbindungen.' +
      ` Mehr zu unserem Datenschutz finden Sie in der <u><a href="${getFmpUrl(
        'privacy'
      )}" target="_blank">Datenschutzerklärung</a></u>.`
  },
  {
    question:
      'Sind die Dokumente, die ich beim Funeral Marketplace erstellt habe, per se rechtsgültig?',
    answer:
      'Unsere Dokumentvorlagen für Patientenverfügung, Betreuungsverfügung und Vorsorgevollmacht, usw., entsprechen den aktuellen Empfehlungen und Vorgaben des Bundesministeriums für Justiz. Sie können die ausgefüllten Dokumente zusätzlich durch einen Notar prüfen lassen.<br />Außerdem haben Sie die Möglichkeit, Ihre eigenen Dokumente hochzuladen und zu verwalten.'
  },
  {
    question:
      'Wie erfahren meine Angehörigen oder Dienstleister, dass ich meine Dokumente beim ' +
      'Funeral Marketplace hinterlegt habe?',
    answer:
      'Ihre persönliche Notfallkarte erhalten Sie schon wenige Tage nach Ihrer Registrierung im Zentralregister. Diese enthält Ihren Namen, Ihr Geburtsdatum und einen QR-Code. Sollte Ihnen etwas zustoßen, werden die auf der Notfallkarte angegebenen Dienstleister und/oder Vertrauenspersonen benachrichtigt, die wiederum die notwendige Prozesskette initiieren können. Außerdem ermöglicht unsere digitale Mandatssuche eine einfache, schnelle und eindeutige Zuordnung Ihres Mandats.'
  },
  {
    question:
      'Wie viele Menschen nutzen bereits den Funeral Marketplace für das Erstellen, ' +
      'Hinterlegen und Verwalten von Dokumenten?',
    answer: `Absicherung nach dem Tod bewegt die Menschen. Nicht nur durch die Corona-Pandemie rücken die Themen Vorsorge und Absicherung im Notfall in das Bewusstsein der Bevölkerung. Dies drückt sich unter Anderem auch in der rasant steigenden Zahl von Neuregistrierungen im Zentralregister aus. Handeln Sie jetzt und <u><a href="${getFmpUrl(
      'login/signup-as-private-customer'
    )}" target="_blank">beginnen Sie mit wenigen Klicks mit der Vorsorge für den Ernstfall.</a></u>`
  },
  {
    question:
      'Kann ich auch für einen oder mehrere Angehörige ein Mandat anlegen und registrieren?',
    answer:
      'Die eindeutige und unkomplizierte Zuordnung eines Mandats im Ernstfall ist entscheidend! Daher raten wir bewusst dazu, nur Mandate für Ihre eigene Person, bzw. eine verstorbene Person anzulegen. Selbstverständlich können Sie Freunde und Familienangehörige beim Anlegen ihrer Mandate unterstützen.'
  },
  {
    question:
      'Wie kann ich Angehörige über mein angelegtes Mandat beim Funeral Marketplace informieren, ' +
      'auch wenn ich das Mandat nicht registriert habe?',
    answer:
      'Zugriff auf das Mandat ohne Registrierung im Zentralregister haben nur der ausgewählte Dienstleister und Personen mit denen Sie Ihr Passwort zum persönlichen Account geteilt haben. Zur Nutzung der weiteren Vorteile des Zentralregisters, wie z.B. die schnelle und eindeutige Zuordnung Ihres Mandats im Ernstfall durch Dritte, bitten wir Sie sich zu registrieren.'
  }
];

export default faqsItems;
