import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from 'components/Button';

import styles from './HintBadge.module.scss';

const propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  link: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  title: PropTypes.node
};

const HintBadge = (props) => {
  const { className, icon, link, title, subtitle } = props;

  return (
    <Button className={cn(styles.hintBadge, className)} link={link}>
      {icon && <span className={styles.hintBadgeIcon}>{icon}</span>}
      {title && <h4 className={styles.hintBadgeTitle}>{title}</h4>}
      {subtitle && <div className={styles.hintBadgeDescription}>{subtitle}</div>}
    </Button>
  );
};

HintBadge.propTypes = propTypes;

export default HintBadge;
