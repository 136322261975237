import React from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import cn from 'classnames';

import ContentContainer from 'components/ContentContainer';
import mandateFeatures from '../../constants/mandateFeatures';

import styles from './MandateFeatures.module.scss';

const MandateFeatures = () => {
  const [offsetX, setOffsetX] = React.useState(0);
  const containerRef = React.useRef();
  const freeTitleRef = React.useRef();
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.x > -2 && offsetX < 690) {
        setOffsetX((prevState) => prevState + 230);
      }
      if (currPos.x < -232 && offsetX > 70) {
        setOffsetX((prevState) => prevState - 230);
      }
    },
    [offsetX],
    freeTitleRef,
    false,
    100,
    containerRef
  );

  return (
    <div ref={containerRef} className={styles.mandateFeatures}>
      <ContentContainer style={{ width: '100%' }}>
        <div className={styles.mandateFeaturesContainer}>
          <div className={styles.mandateFreeFeaturesWrapper}>
            <div className={styles.mandateFreeFeatures}>
              {mandateFeatures
                .filter((feature) => feature.type === 'free')
                .map((feature) => (
                  <div
                    className={cn(
                      styles.mandateFeatureCard,
                      styles.mandateFeatureCard_free
                    )}
                    key={feature.title}
                  >
                    <img
                      src={feature.icon}
                      alt={feature.title}
                      className={cn(
                        styles.mandateFeatureIcon,
                        styles[feature.iconClassName]
                      )}
                    />
                    <div className={styles.mandateFeatureTitle}>{feature.title}</div>
                    <div className={styles.mandateFeatureText}>{feature.text}</div>
                  </div>
                ))}
              <div
                ref={freeTitleRef}
                className={cn(styles.bracketText, styles.bracketTextTablet)}
                style={{ left: `${70 + offsetX}px` }}
              >
                KOSTENFREI
              </div>
            </div>
            <div className={styles.mandateFeaturesBracket}>
              <div className={cn(styles.bracket, styles.firstBracket)} />
              <div className={cn(styles.bracketText, styles.bracketTextLaptop)}>
                KOSTENFREI
              </div>
              <div className={cn(styles.bracket, styles.lastBracket)} />
            </div>
          </div>
          <div className={styles.mandatePaidFeaturesWrapper}>
            <div className={styles.mandatePaidFeatures}>
              {mandateFeatures
                .filter((feature) => feature.type === 'paid')
                .map((feature) => (
                  <div
                    className={cn(
                      styles.mandateFeatureCard,
                      styles.mandateFeatureCard_paid
                    )}
                    key={feature.title}
                  >
                    <img
                      src={feature.icon}
                      alt={feature.title}
                      className={cn(
                        styles.mandateFeatureIcon,
                        styles[feature.iconClassName]
                      )}
                    />
                    <div className={styles.mandatePaidFeatureLabel}>– OPTIONAL –</div>
                    <div className={styles.mandateFeatureTitle}>{feature.title}</div>
                    <div className={styles.mandateFeatureText}>{feature.text}</div>
                  </div>
                ))}
            </div>
            <div className={styles.mandateFeaturesBracket}>
              <div className={cn(styles.bracket, styles.firstBracket)} />
              <div className={styles.bracketText}>
                <div>
                  EINMALIG 99,- €
                  <br />
                  <span style={{ fontSize: 12, fontWeight: 'normal' }}>
                    inkl. 19 % MwSt.
                  </span>
                </div>
                <p className={styles.bracketTextSeparator}>oder</p>
                <div>
                  MONATLICH 0,99 €
                  <br />
                  <span style={{ fontSize: 12, fontWeight: 'normal' }}>
                    inkl. 19 % MwSt.
                  </span>
                </div>
              </div>
              <div className={cn(styles.bracket, styles.lastBracket)} />
            </div>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
};

export default MandateFeatures;
