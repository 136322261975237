import React, { useEffect } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import cn from 'classnames';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

// Layout component should come first in imports
// since it holds global styles and css reset which should be applied first
import Layout from 'components/Layout';
import ContentContainer from 'components/ContentContainer';
import PageContent from 'components/PageContent';
import PageSubtitle from 'components/PageSubtitle';
import PageTitle from 'components/PageTitle';
import PageTitleGroup from 'components/PageTitleGroup';
import Seo from 'components/seo';
import HintBadge from 'components/HintBadge';
import MandateFeatures from 'components/MandateFeatures/MandateFeatures';
import RegistrySearch from 'components/RegistrySearch';
import Button from 'components/Button';
import KnownLogos from 'components/KnownLogos';
import Faqs from 'components/FaqSection';
import Contacts from 'components/ContactsSection';
import InfoSection from 'components/InfoSection';

import GermanFlagIcon from 'images/svg/GermanFlag.svg';
import SecureConnectionIcon from 'images/svg/SecureConnection.svg';
import IconTestament from 'images/svg/IconTestament.svg';

import mandateEmergencyCardImage from 'images/emergency-card.png';
import lockImage from 'images/lock.png';
import trustedShopsImage from 'images/trusted-shops.png';
import createMandatImage from 'images/about-us-features/createMandat.png';
import remembranceImage from 'images/about-us-features/remembrance.png';
import viewProductsImage from 'images/about-us-features/viewProducts.png';

import anchorLinks from '../../constants/provisionAnchorLinks';
import faqsItems from '../../constants/faqsItemsProvisionPage';

import styles from './Provision.module.scss';

const Provision = () => {
  useEffect(() => {
    Aos.init({ duration: 600 });
  }, []);

  const renderedAnchorLinks = anchorLinks.map((anchorLink, anchorNumber) => {
    return (
      <AnchorLink
        to={`/vorsorgen-und-verfuegen/${anchorLink.anchor}`}
        key={anchorLink.title}
      >
        <div
          className={styles.anchorLink}
          data-aos="fade"
          data-aos-delay={100 * anchorNumber}
          data-aos-offset="100"
          data-aos-anchor={'#anchor-link0'}
          id={'anchor-link' + anchorNumber}
        >
          <img src={anchorLink.image} style={anchorLink.imageStyle} alt="" />
          <span className={styles.anchorTitle}>{anchorLink.title}</span>
        </div>
      </AnchorLink>
    );
  });

  return (
    <Layout>
      <Seo
        title="Vorsorgen & Verfügen"
        description="Für Ihren Notfall oder Sterbefall: Erstellen, hinterlegen und verwalten Sie alle wichtigen Dokumente in Ihrem persönlichen digitalen Tresor. Im Fall der Fälle können Ihre Angehörigen oder Dienstleister darauf zugreifen – und in Ihrem Sinne handeln."
        imageURL={createMandatImage}
        faq={faqsItems}
      />
      <ParallaxProvider>
        <ContentContainer>
          <PageContent>
            <PageTitleGroup>
              <PageTitle>VORSORGEN & VERFÜGEN</PageTitle>
              <PageSubtitle>Sicher auf dem Funeral Marketplace</PageSubtitle>

              <ContentContainer className={styles.badgeContainer}>
                <HintBadge
                  icon={<IconTestament />}
                  subtitle={
                    <>
                      PATIENTENVERFÜGUNG TESTAMENTVORLAGE{' '}
                      <span className="bi">kostenlos</span> nutzen
                      <br />
                      <span className="bi">sicher</span> verwahren
                    </>
                  }
                  link="//convela.cloud/mandate/digital-safe"
                />
              </ContentContainer>
            </PageTitleGroup>

            <div className={styles.section}>
              <div className={styles.hero}>
                <Parallax
                  className={styles.heroBackgroundContainer}
                  styleInner={{
                    position: 'absolute',
                    top: -80,
                    right: 0,
                    bottom: -40,
                    left: 0
                  }}
                  y={[-30, 30]}
                >
                  <div className={styles.heroBackgroundImage} />
                </Parallax>
                <h1 className={styles.heroTitle}>
                  Damit Ihr Wille <br className={styles.mobileBreak} /> geschieht:
                </h1>
                <h1 className={styles.heroTitle}>
                  Vorsorgen, verfügen, Klarheit hinterlassen.
                </h1>
                <h2 className={styles.heroSubtitle}>
                  Für Ihren Notfall oder Sterbefall:
                </h2>
                <p className={styles.heroDescription}>
                  Erstellen, hinterlegen und verwalten Sie
                  <br className={styles.mobileBreak} />
                  <b> alle wichtigen Dokumente </b>
                  <br className={styles.mobileBreak} />
                  in Ihrer persönlichen Cloud. <br className={styles.mobileLaptopBreak} />
                  <span className={styles.heroDescriptionParagraph}>
                    Im Fall der Fälle können Ihre Angehörigen{' '}
                    <br className={styles.mobileBreak} />
                    oder Dienstleister darauf zugreifen –{' '}
                    <br className={styles.mobileBreak} />
                    und in Ihrem Sinne handeln.
                  </span>
                </p>
              </div>

              <div className={styles.anchorLinks}>{renderedAnchorLinks}</div>
            </div>
            <div
              className={cn(styles.section, styles.createMandateSection)}
              id="create-mandate"
            >
              <h3 className={styles.sectionTitle}>Mandat anlegen</h3>
              <h4 className={styles.sectionSubtitle}>
                Dokumente erstellen, hinterlegen, verwalten: <br /> Alles zentral, sicher
                & einfach in Ihrem persönlichen digitalen Tresor.
              </h4>
              <p className={styles.sectionDescription}>
                <span className={styles.mandateDescriptionParagraph}>
                  Von der <span>Betreuungsverfügung </span> übers <span>Testament </span>
                  bis hin zur <span>Vorsorgevollmacht: </span>
                </span>
                <br className={styles.mobileTabletBreak} /> Erstellen, hinterlegen und
                verwalten Sie diese und weitere wichtige Dokumente in Ihrem persönlichen
                digitalen Tresor. So haben Sie Ihre Dokumente jederzeit zentral, einfach
                und sicher in der Verfügung. Alles, was Sie dafür tun müssen: ein Mandat
                anlegen.
                <br />
                Dieser Service ist für Sie kostenfrei.
              </p>
              <div>
                <h5 className={styles.mandateSectionInnerTitle}>
                  Diese Funktionen beinhaltet Ihr Mandat:
                </h5>
                <MandateFeatures />
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  type="primary"
                  color="blue"
                  title="MANDAT ANLEGEN"
                  link="//convela.cloud/login/signup-as-private-customer"
                />
              </div>
            </div>
            <div className={styles.section} id="mandate-register">
              <h3 className={styles.sectionTitle}>Mandat registrieren</h3>
              <h4 className={styles.sectionSubtitle}>
                Im Zentralregister registrieren und Notfallkarte erhalten: <br /> Damit
                Sie und Ihre Dokumente ganz sicher gefunden werden.
              </h4>
              <p className={styles.sectionDescription}>
                Wenn Sie sich für die Registrierung Ihres Mandats entscheiden, ist dank
                dieser Registrierung eines immer gesichert:
                <br className={styles.mobileBreak} />
                <span> Sie werden gefunden</span> – über Ihren Namen und Ihre Geburtsdaten
                im Zentralregister und über die Notfallkarte in Ihren persönlichen
                Unterlagen.
              </p>
              <div className={styles.mandateRegistryFeature}>
                <div className={styles.registrySearchWrapper}>
                  <RegistrySearch />
                </div>
                <div className={styles.mandateRegisterTextWrapper}>
                  <div className={styles.mandateRegistryFeatureTitle}>
                    <p>Zentralregister:</p>
                    So finden Angehörige und Dienstleister Ihr registriertes Mandat und
                    Ihre Dokumente:
                  </div>
                  <div className={styles.mandateRegistryFeatureText}>
                    Sollte Ihnen etwas zustoßen, können Ihre Angehörigen und Dienstleister
                    über die Eingabe Ihres Namens und Geburtsdatums in unser
                    Zentralregister sehen, dass Sie bei uns ein registriertes Mandat
                    hinterlegt haben; sie sehen zudem, welche Vertrauensperson und welche
                    Ansprechpartner Sie für den Fall der Fälle hinterlegt haben und können
                    Kontakt zu diesen aufnehmen, um Ihren Dokumenten, Wünschen und
                    Verfügungen gemäß zu handeln.
                  </div>
                </div>
              </div>
              <div className={styles.mandateRegistryFeature}>
                <div className={styles.registrySearchWrapper}>
                  <div
                    className={styles.emergencyCardContainer}
                    data-aos="fade"
                    data-aos-delay={200}
                  >
                    <img
                      className={styles.emergencyCardImage}
                      src={mandateEmergencyCardImage}
                      alt="Notfallkarte"
                    />
                  </div>
                </div>
                <div className={styles.mandateRegisterTextWrapper}>
                  <div className={styles.mandateRegistryFeatureTitle}>
                    <p>Notfallkarte:</p>
                    So erfahren Angehörige und Dienstleister von Ihrem registrierten
                    Mandat und Ihren Dokumenten
                  </div>
                  <div className={styles.mandateRegistryFeatureText}>
                    Wenn Sie Ihr Mandat registriert haben, erhalten Sie von uns innerhalb
                    weniger Tage <span>Ihre persönliche Notfallkarte</span>. Diese enthält
                    Ihren Namen, Ihr Geburtsdatum und einen QR-Code; sie ist der physische
                    Hinweis darauf, dass Sie in unserem Zentralregister registriert sind.
                    Sollte Ihnen etwas zustoßen, können Ihre Angehörigen oder
                    Dienstleister dank Ihrer Notfallkarte Kontakt zu uns aufnehmen. Führen
                    Sie daher Ihre Notfallkarte am besten stets bei sich, z.B. in Ihrem
                    Portemonnaie oder Kalender.
                  </div>
                </div>
              </div>
              <div className={styles.mandateRegistryCTAButtonWrapper}>
                <Button
                  type="primary"
                  color="green"
                  title="Mandat registrieren"
                  link="//convela.cloud/login/signup-as-private-customer"
                  className={styles.mandateRegistryCTAButton}
                />
              </div>
            </div>
            <div className={cn(styles.section, styles.blueSection)} id="data-protection">
              <ContentContainer>
                <h3 className={styles.sectionTitle}>Datensicherheit</h3>
                <h4 className={styles.sectionSubtitle}>Ihre Daten sind sicher.</h4>
                <div className={styles.privacy}>
                  <p>
                    Der Schutz Ihrer sensiblen Daten hat oberste Priorität. Wir hosten
                    Ihre Daten sicher und entsprechend der DSGVO ausschließlich auf
                    Servern in Deutschland.
                  </p>
                  <img
                    src={lockImage}
                    alt="Ihre Daten sind sicher."
                    className={styles.privacyLockIcon}
                  />
                  <div className={styles.privacyIcons}>
                    <div className={styles.privacyIcon}>
                      <span>
                        zertifizierte
                        <br />
                        Sicherheit
                      </span>
                      <img src={trustedShopsImage} alt="Trusted Shops" />
                    </div>
                    <div className={styles.privacyIcon}>
                      <span>
                        verschlüsselte
                        <br />
                        Verbindung
                      </span>
                      <SecureConnectionIcon />
                    </div>
                    <div className={styles.privacyIcon}>
                      <span>
                        Serverstandort
                        <br />
                        Deutschland
                      </span>
                      <GermanFlagIcon />
                    </div>
                  </div>
                </div>
              </ContentContainer>
            </div>
            <div className={cn(styles.section, styles.faqSection)} id="faq">
              <h3 className={styles.sectionTitle}>FAQ</h3>
              <h4 className={styles.sectionSubtitle}>
                Klare Antworten auf Fragen,
                <br />
                die uns immer wieder gestellt werden.
              </h4>
              <Faqs faqsItems={faqsItems} />
            </div>
            <div className={cn(styles.section, styles.aboutUsSection)} id="about-us">
              <h3 className={styles.sectionTitle}>Über uns: Funeral Marketplace</h3>
              <h4 className={styles.sectionSubtitle}>
                Alles über eine Plattform:
                <br />
                Vorsorgen und verfügen, bestatten,
                <br className={styles.tabletLaptopBreak} /> trauern{' '}
                <br className={styles.mobileBreak} /> und gedenken.
              </h4>
              <p className={styles.sectionDescription}>
                Abschiedsthemen sind nicht leicht. Aus diesem Grund haben wir es uns zur
                Aufgabe gemacht, Ihnen alle damit verbundenen Aufgaben, Entscheidungen und
                Handlungen so einfach wie möglich zu machen. Dazu entwickeln und
                digitalisieren wir Services, machen Produkte digital verfügbar und
                bestehende Strukturen transparent, testen Hersteller im Hinblick auf
                Qualität und Einstellung und vernetzen wichtige Akteure. So
                demokratisieren wir die „Blackbox“ Bestattungsbranche.
              </p>
              <h3 className={styles.aboutUsSubtitle}>Für Sie bedeutet das:</h3>
              <p className={styles.aboutUsDescription}>
                Mehr Selbstbestimmung, mehr Auswahl, mehr Einflussnahme, mehr Transparenz
                und mehr Qualität in allen Themen – im Vorsorgen und Verfügen, im Trauern
                und Gedenken <br className={styles.mobileBreak} /> sowie Bestatten.
              </p>
              <div className={styles.aboutUsFeatures}>
                <div
                  className={styles.aboutUsFeature}
                  data-aos-offset={160}
                  data-aos="fade"
                >
                  <img src={createMandatImage} alt="MANDAT ANLEGEN" />
                  <Button
                    className={styles.aboutUsButton}
                    type="primary"
                    color="blue"
                    title="MANDAT ANLEGEN"
                    link="//convela.cloud/login/signup-as-private-customer"
                  />
                </div>
                <div
                  className={styles.aboutUsFeature}
                  data-aos="fade"
                  data-aos-offset={160}
                  data-aos-delay={100}
                >
                  <img src={viewProductsImage} alt="PRODUKTE ANSEHEN" />
                  <Button
                    className={styles.aboutUsButton}
                    type="primary"
                    color="blue"
                    title="PRODUKTE ANSEHEN"
                    link="//convela.cloud/categories"
                  />
                </div>
                <div
                  className={styles.aboutUsFeature}
                  data-aos="fade"
                  data-aos-offset={160}
                  data-aos-delay={200}
                >
                  <img src={remembranceImage} alt="GEDENKEN" />
                  <Button
                    className={styles.aboutUsButton}
                    type="primary"
                    color="blue"
                    title="GEDENKEN"
                    link="//gedenkseiten.de?utm_source=about.convela.cloud"
                  />
                </div>
              </div>
            </div>
            <div className={cn(styles.section, styles.blueSection)}>
              <h3 className={styles.sectionTitle}>Bekannt aus:</h3>
              <KnownLogos />
            </div>
            <div className={styles.contactSection} id="contact">
              <h3 className={styles.sectionTitle}>Kontakt</h3>
              <h4 className={styles.sectionSubtitle}>
                Fragen, Anregungen, Kritik?
                <br />
                Gern.
              </h4>
              <Contacts />
            </div>
            <div id="info">
              <InfoSection backToToplink="/vorsorgen-und-verfuegen/#page-start" />
            </div>
          </PageContent>
        </ContentContainer>
      </ParallaxProvider>
    </Layout>
  );
};

export default Provision;
